.i {
    display: flex;
    height: 100vh;
}

.i-left {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-intro {
    font-size: 30px;
    font-weight: normal;
}

.i-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: circle(50% at 50% 50%);
}

.i-right-wrapper {
    padding: 25px;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* @media (width <= 600) {
    .i-right-wrapper {
        display: none;
    }
  } */

@media (max-width: 800) {
    .i-right {
        background-color: brown;
    }
}

.i-name {
    font-size: 60px;
}

.i-title {
    height: 40px;
    overflow:hidden;
    font-size: 35px;
    line-height: 40px;
}

.i-title-wrapper {
    font-weight: 600;
    overflow: hidden;
    height: 40px;
}

.i-title-wrapper-list {
    margin: 0;
    text-align: left;
    list-style: none;
    padding-inline-start: 0;
    
    -webkit-animation-name: change;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

.i-title-wrapper-list-item {
    height: 160px;
    line-height:40px;
    margin:0;
    color: darkolivegreen;
}

.r {
    margin: 50px;;
}
  
@-webkit-keyframes change {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    33.32%,45.98% {transform:translate3d(0,-50%,0);}
    49.98%,62.64% {transform:translate3d(0,-75%,0);}
    66.64%,79.3% {transform:translate3d(0,-50%,0);}
    83.3%,95.96% {transform:translate3d(0,-25%,0);}
}
  
@-o-keyframes change {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    33.32%,45.98% {transform:translate3d(0,-50%,0);}
    49.98%,62.64% {transform:translate3d(0,-75%,0);}
    66.64%,79.3% {transform:translate3d(0,-50%,0);}
    83.3%,95.96% {transform:translate3d(0,-25%,0);}
}

@-moz-keyframes change {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    33.32%,45.98% {transform:translate3d(0,-50%,0);}
    49.98%,62.64% {transform:translate3d(0,-75%,0);}
    66.64%,79.3% {transform:translate3d(0,-50%,0);}
    83.3%,95.96% {transform:translate3d(0,-25%,0);}
}
  
@keyframes change {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    33.32%,45.98% {transform:translate3d(0,-50%,0);}
    49.98%,62.64% {transform:translate3d(0,-75%,0);}
    66.64%,79.3% {transform:translate3d(0,-50%,0);}
    83.3%,95.96% {transform:translate3d(0,-25%,0);}
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}
  
a.social:hover {
  transform: translateY(-2px);
}
  
a.linkedin {
  color: #0e76a8;
}

a.github {
  color: #000;
}

a.twitter {
  color: #49a1eb;
}